
import { Component, Vue, Watch } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import { Action, Getter, Mutation } from "vuex-class";
import { IMovementListRequest, IMovement } from "@/types/movement";
import { IPagination, ISelectItem } from "@/types";
import { IWarehouseListRequest } from "@/types/warehouse";
import { ICustomerListRequest } from "@/types/customer";
import { IProductListRequest } from "@/types/product";
import { ICustomerProductListRequest } from "@/types/customer_product";
import { IMakerListRequest } from "@/types/maker";

@Component({})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  // movement
  @Action("movement/adminGetList")
  public getMovementList!: (request: IMovementListRequest) => Promise<boolean>;

  @Getter("movement/list")
  public movementList!: IMovement[];

  @Getter("movement/pagination")
  public pagination!: IPagination<IMovement[]>;

  @Mutation("movement/clear")
  public movementClear!: () => void;

  // warehouse
  @Action("warehouse/adminGetList")
  public getWarehouseList!: (
    request: IWarehouseListRequest
  ) => Promise<boolean>;

  @Getter("warehouse/selectItem")
  public warehouseList!: ISelectItem[];

  @Mutation("warehouse/clear")
  public warehouseClear!: () => void;

  // customer
  @Action("customer/adminGetList")
  public getCustomerList!: (request: ICustomerListRequest) => Promise<boolean>;

  @Getter("customer/selectItem")
  public customerList!: ISelectItem[];

  @Mutation("customer/clear")
  public customerClear!: () => void;

  // maker
  @Action("maker/adminGetList")
  public getMakerList!: (request: IMakerListRequest) => Promise<boolean>;

  @Getter("maker/selectItem")
  public makerList!: ISelectItem[];

  @Mutation("maker/clear")
  public makerClear!: () => void;

  // product
  @Action("product/adminGetList")
  public getProductList!: (request: IProductListRequest) => Promise<boolean>;

  @Getter("product/selectItem")
  public productList!: ISelectItem[];

  @Mutation("maker/clear")
  public productClear!: () => void;

  // customer_product
  @Action("customer_product/adminGetList")
  public getCustomerProductList!: (
    request: ICustomerProductListRequest
  ) => Promise<boolean>;

  @Getter("customer_product/selectItem")
  public customerProductList!: ISelectItem[];

  @Mutation("customer_product/clear")
  public customerProductClear!: () => void;

  // watch
  @Watch("$route.query")
  public async changeQuery() {
    this.queryToParams();
    await this.getMovementList(this.requestParams);
  }

  // パラメータ定義
  public requestParams: IMovementListRequest = {
    page: 1,
    maker_id: 0,
    product_id: 0,
    warehouse_id: 0,
    customer_id: 0,
    customer_product_id: 0,
    status: 0,
    search: "",
    search_span_start: "",
    search_span_end: "",
    sort_date_column: "movement_date",
    sort_direction: "",
    is_memo: false
  };

  //
  // variables
  //
  public movement_status = Vue.prototype.$movementStatus;
  public menu = {
    start: false,
    end: false,
    day: false
  };
  public add_span_end = false;
  public span_end_show = true;
  public span_day_show = false;
  public rules = {};

  public status_items = [
    { value: 0, text: "全て表示" },
    { value: this.movement_status.storing, text: "入荷" },
    { value: this.movement_status.shipment, text: "出荷/予定" },
    { value: this.movement_status.movement, text: "倉庫移動" }
  ];

  public sort_items = [
    { value: "", text: "選択なし" },
    { value: "asc", text: "昇順" },
    { value: "desc", text: "降順" }
  ];

  public headers = [
    {
      text: "処理日",
      value: "movement_date",
      sortable: false
    },
    { text: "ロットNo", value: "lot_no", sortable: false },
    { text: "商品名", value: "product_info", sortable: false },
    {
      text: "顧客品番",
      value: "customer_pn",
      sortable: false
    },

    { text: "状況", value: "status_disp", sortable: false },
    {
      text: "移動元 → 移動先",
      value: "before_after_warehouse",
      sortable: false
    },
    { text: "処理数", value: "quantity", sortable: false, align: "end" },
    {
      text: "在庫数",
      value: "current_stock_quantity",
      sortable: false,
      align: "end"
    },
    {
      text: "仕入単価",
      value: "buying_unit_price",
      sortable: false,
      align: "end"
    },
    {
      text: "売単価",
      value: "selling_unit_price",
      sortable: false,
      align: "end"
    },
    {
      text: "注文番号",
      value: "received_and_order_no",
      sortable: false
    },
    {
      text: "納品No",
      value: "shipment_no",
      sortable: false
    },
    {
      text: "請求No",
      value: "invoice_no",
      sortable: false
    },
    { text: "在庫メモ", value: "stock_memo", sortable: false },
    {
      text: "輸送費単価編集",
      value: "transport_unit_price_edit",
      sortable: false
    }
  ];

  //
  // methods
  //
  private async created() {
    this.movementClear();
    this.warehouseClear();
    this.customerClear();
    this.makerClear();
    this.productClear();
    this.customerProductClear();
    this.queryToParams();

    await Promise.all([
      this.getMovementList(this.requestParams),
      this.getWarehouseList({ per_page: 0 }),
      this.getCustomerList({ per_page: 0 }),
      this.getMakerList({ per_page: 0 }),
      this.getProductList({ per_page: 0 }),
      this.getCustomerProductList({ per_page: 0 })
    ]);

    if (this.$route.params.product_id) {
      this.headers = [
        {
          text: "処理日",
          value: "movement_date",
          sortable: false
        },
        { text: "ロットNo", value: "lot_no", sortable: false },
        {
          text: "顧客品番",
          value: "customer_pn",
          sortable: false
        },
        {
          text: "状況",
          value: "status_disp",
          sortable: false
        },
        {
          text: "移動元 → 移動先",
          value: "before_after_warehouse",
          sortable: false
        },
        {
          text: "処理数",
          value: "quantity",
          sortable: false
        },
        {
          text: "在庫数",
          value: "current_stock_quantity",
          sortable: false
        },
        {
          text: "仕入単価",
          value: "buying_unit_price",
          sortable: false
        },
        {
          text: "売単価",
          value: "selling_unit_price",
          sortable: false
        },
        {
          text: "発注番号",
          value: "order_no",
          sortable: false
        },
        {
          text: "納品No",
          value: "shipment_no",
          sortable: false
        },
        {
          text: "請求No",
          value: "invoice_no",
          sortable: false
        },
        { text: "在庫メモ", value: "stock_memo", sortable: false },
        {
          text: "輸送費単価編集",
          value: "transport_unit_price_edit",
          sortable: false
        }
      ];
    }
  }

  // urlからパラメータをセットする
  private queryToParams() {
    const routeQuery = this.$route.query;
    const routeParams = this.$route.params;

    this.requestParams = {
      page: routeQuery.page ? Number(routeQuery.page) : 1,
      status: routeQuery.status ? Number(routeQuery.status) : 0,
      maker_id: routeParams.maker_id
        ? Number(routeParams.maker_id)
        : routeQuery.maker_id
        ? Number(routeQuery.maker_id)
        : 0,
      product_id: routeParams.product_id
        ? Number(routeParams.product_id)
        : routeQuery.product_id
        ? Number(routeQuery.product_id)
        : 0,
      warehouse_id: routeQuery.warehouse_id
        ? Number(routeQuery.warehouse_id)
        : 0,
      customer_id: routeQuery.customer_id ? Number(routeQuery.customer_id) : 0,
      customer_product_id: routeQuery.customer_product_id
        ? Number(routeQuery.customer_product_id)
        : 0,
      is_memo: routeQuery.is_memo ? Boolean(routeQuery.is_memo) : false,
      sort_date_column: routeQuery.sort_date_column
        ? decodeURI(routeQuery.sort_date_column as string)
        : "movement_date",
      sort_direction: routeQuery.sort_direction
        ? decodeURI(routeQuery.sort_direction as string)
        : "",
      search_span_start: routeQuery.search_span_start
        ? decodeURI(routeQuery.search_span_start as string)
        : "",
      search_span_end: routeQuery.search_span_end
        ? decodeURI(routeQuery.search_span_end as string)
        : "",
      search: routeQuery.search ? decodeURI(routeQuery.search as string) : ""
    };
  }

  // 1ページ目から検索
  // 主に検索条件が変更されたときに利用
  public async searchFirst() {
    this.requestParams.page = 1;
    await this.paramsToQuery();
  }

  // URLのパラメータをセットする
  private paramsToQuery() {
    this.$router
      .push({
        path: this.$router.currentRoute.path,
        query: {
          page: this.requestParams.page ? String(this.requestParams.page) : "1",
          status: this.requestParams.status
            ? String(this.requestParams.status)
            : "0",
          maker_id: this.requestParams.maker_id
            ? String(this.requestParams.maker_id)
            : "0",
          product_id: this.requestParams.product_id
            ? String(this.requestParams.product_id)
            : "0",
          warehouse_id: this.requestParams.warehouse_id
            ? String(this.requestParams.warehouse_id)
            : "0",
          customer_id: this.requestParams.customer_id
            ? String(this.requestParams.customer_id)
            : "0",
          customer_product_id: this.requestParams.customer_product_id
            ? String(this.requestParams.customer_product_id)
            : "0",
          is_memo: this.requestParams.is_memo
            ? String(this.requestParams.is_memo)
            : "",
          search: this.requestParams.search,
          sort_date_column: this.requestParams.sort_date_column,
          sort_direction: this.requestParams.sort_direction,
          search_span_start: this.requestParams.search_span_start,
          search_span_end: this.requestParams.search_span_end
        }
      })
      .catch(() => {});
  }

  //行をクリックした際の挙動
  public rowClick(movement: IMovement) {
    if (movement.status == this.movement_status.storing) {
      this.$router.push(`/admin/movement/${movement.id}/movement_storing/edit`);
    } else if (movement.status == this.movement_status.shipment) {
      this.$router.push(
        `/admin/movement/${movement.shipment_id}/shipment/edit`
      );
    } else if (movement.status == this.movement_status.movement) {
      this.$router.push(`/admin/movement/${movement.id}/edit`);
    }
    return;
  }

  //輸送費単価修正ボタンをクリックした際の挙動
  public transport_unit_price_edit_selected(item: IMovement) {
    this.$router.push(`/admin/transport_unit_price/${item.order_id}/edit`);
  }

  // 開始日、終了日、日付指定のリセット
  public clearSearchStart() {
    this.requestParams.search_span_start = "";
    this.searchFirst();
  }

  public clearSearchEnd() {
    this.requestParams.search_span_end = "";
    this.searchFirst();
  }

  public clearSearchSpanDay() {
    this.requestParams.search_span_start = "";
    this.requestParams.search_span_end = "";
    this.searchFirst();
  }

  // 日付指定チェックボックスをクリックした際の挙動
  public span_toggle() {
    if (this.add_span_end == false) {
      this.requestParams.search_span_end = "";
      this.requestParams.search_span_start = "";
      this.span_end_show = true;
      this.span_day_show = false;
      this.searchFirst();
    } else {
      this.requestParams.search_span_end = "";
      this.requestParams.search_span_start = "";
      this.span_end_show = false;
      this.span_day_show = true;
      this.requestParams.search_span_end = this.requestParams.search_span_start;
      this.searchFirst();
    }
  }

  // 日付指定した際の挙動
  public async searchFirstSpanDay() {
    this.requestParams.page = 1;
    this.requestParams.search_span_end = this.requestParams.search_span_start;
    await this.paramsToQuery();
  }

  // 期間選択時のバリデーション
  public spanEndRule() {
    if (
      !this.requestParams.search_span_end ||
      !this.requestParams.search_span_start ||
      this.requestParams.search_span_end >= this.requestParams.search_span_start
    ) {
      return true;
    }
    return "正しい日付を入力してください";
  }
}
